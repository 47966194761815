
import { ref, reactive, defineComponent, onMounted } from 'vue';

import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';

import { LotApi } from '@/classes/api/LotApi';
import CustomError from '@/classes/models/CustomError';
import { Lot } from '@/classes/models/Lot';
import isObjectsMatches from "@/utils/isObjectsMatches";

import NewForm from './LotForm.vue';

export default defineComponent({
  components: {
    'new-form': NewForm,
    DataTable, Column, Button, InputText, Checkbox
  },

  setup() {
    const item = reactive<Lot>(new Lot({}));
    const items = ref<Lot[]>([]);
    const selected = ref<Lot[]>([]);
    const newWindow = ref(false);
    const loading = ref(false);
    const editMode = ref(false);
    const oldItem = reactive<Lot>(new Lot({}));

    const confirm = useConfirm();
    const toast = useToast();
    const api = new LotApi();

    const update = async () => {
      loading.value = true;
      try {
        items.value = await api.fetchAll();
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    }

    onMounted(() => {
      update();
    });

    // eslint-disable-next-line
    const cellEditInit = (event: any) => {
      oldItem.replaceBy(new Lot(event.data));
    }

    // eslint-disable-next-line
    const cellEditComplete = async (event: any) => {
      const lot = new Lot(event.data);
      if (!lot.excludeFromExport) {
        lot.excludeFromExport = false;
      }
      if (!isObjectsMatches(lot, oldItem)) {
        loading.value = true;
        try {
          await api.update(lot);
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      }
    }

    const edit = (selected: Lot) => {
      item.replaceBy(selected);
      editMode.value = true;
      newWindow.value = true;
    }

    const confirmDeleteSelected = () => {
      confirm.require({
        message: 'Действительно хотите удалить выбранные?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          loading.value = true;
          try {
            for (const item of selected.value) {
              item.id && (await api.remove(+item.id));
            }
            toast.add({
              severity: 'success',
              summary: 'Успешно',
              detail: 'Удалено',
              life: 3000,
            });
            await update();
          } catch (error) {
            if (error instanceof CustomError) {
              error.show('error', 5000, toast);
            } else {
              console.log(error);
            }
          }
          loading.value = false;
        },
      });
    };

    return {
      items,
      selected,
      loading,
      cellEditInit,
      cellEditComplete,
      confirmDeleteSelected,
      newWindow,
      item,
      editMode,
      update,
      edit
    }
  }
});
