import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  name: {
    title: 'Наименование',
    type: 'input',
  },
  description: {
    title: 'Описание',
    type: 'textarea',
  },
  price: {
    title: 'Себестоимость, руб',
    type: 'input',
    variableType: 'number',
  },
};

export default fields;
